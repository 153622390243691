import storage from "@/utils/storage";

export const useLogin = () => {
  const token = storage.getItem("token");
  let isLogin = false;
  if (token) {
    isLogin = true;
  }
  return { isLogin, token };
};

export const useNeedLogin = () => {
  return {};
};
