import router from "./router";
import store from "./store";
import { ElLoading } from "element-plus";
import { clearPending } from "@u/http";
import storage from "@u/storage";
import StoreService from "@/services/store.service";
import LineService from "@/services/line.service";
import moment from 'moment'

let appendingInstance = null;

router.beforeEach(async (to, from, next) => {
  // console.log(to)
  // console.log(store)
  console.log("beforeEach", "進入");
  const pathFromName = from.name
  storage.setItem('pathFromName',pathFromName)
  appendingInstance = ElLoading.service({
    lock: true,
    fullscreen: true,
    // background: "rgba(0, 0, 0, 0.7)",
    text: "資料載入中.......",
  });
  clearPending();
  storage.config = { type: "session" };

  // 抓api判斷網站是否正確存在
  await store.dispatch("shop/getBrand");
  // if (store.state.shop.is_brand_live === false) {
  //   window.location.href = "https://www.okshop.tw";
  // }

  //get liff id
  await StoreService.isStoreLife().catch(() => {
    storage.removeItem("token");
    if (to.name !== "Closed") {
      next({ path: "/closed" });
      return;
    }
  });
  const brand = storage.getItem('brand')
  // 首頁跳轉商店
  if (to.path === '/') {
    if (brand.brand_type === 2) {
      next({ path: "/shop" });
      return
    } else {
      next({ path: "/home" });
      return
    }
  }
  // 判斷進入的頁面是否需要登入
  const isInClientNeedLogin =
    !to.meta.isInClientNoLogin && LineService.isInClient();
  if (to.meta.needLogin === true || isInClientNeedLogin) {
    if (storage.getItem("lineExpired") === true) await LineService.logout();
    // 傳入cooperation_id和coupon_id要導向對應的網址
    // 沒登入要進行登入
    if (
      storage.getItem("token") === null ||
      (to.query.cooperation_id && to.query.coupon_id)
    ) {
    const goPath = await LineService.login();
      if (goPath !== undefined) {
        next({ path: goPath });
        return;
      }
    }
  }

  // 判斷商店狀態
  // if (
  //   ["okshop+s"].includes(storage.getItem("store_type")) &&
  //   to.meta.shop_type === "diner"
  // ) {
  //   next({ path: "/" });
  //   return;
  // }

  // const isIncludeShop = !["okshop+s", "okshop+ds", "okshop+dfs"].includes(
  //   storage.getItem("store_type")
  // );
  // if (isIncludeShop && to.meta.shop_type === "shop") {
  //   next({ path: "/" });
  //   return;
  // }

  document.title = storage.getItem("brand").title;
  console.log("getLoad");
  // console.log(store.state)
  await store.dispatch("shop/getLoad");

  // 判斷營業時間
  let isOpen = store.state.shop.open_hours.some(item => item.status === 1)
  // console.log(`${isOpen ? "有營業" : "未營業"}`);

  // 判斷取貨方式
  let isPickUp = null
  if (brand.brand_type !== 2) {
    if (
      // store.state.shop.setting.for_here !== 0 ||
      // store.state.shop.setting.to_go !== 0 ||
      // store.state.shop.setting.order_in !== 
      store.state.shop.setting.delivery.status === 1 ||
      store.state.shop.setting.here.status === 1 ||
      store.state.shop.setting.order.status === 1 ||
      store.state.shop.setting.togo.status === 1
    ) {
      // console.log("商店取貨方式開啟");
      isPickUp = true
      // next({ path: "/" });
      // return;
    }
    else {
      // console.log("商店取貨方式關閉");
      isPickUp = false
    }
  } else if (brand.brand_type === 2) {
    if (
      store.state.shop.shop_shipping.delivery.status === 1 ||
      store.state.shop.shop_shipping.instore.status === 1
    ) {
      // console.log("商店取貨方式開啟");
      isPickUp = true
      // next({ path: "/" });
      // return;
    } else {
      // console.log("商店取貨方式關閉");
      isPickUp = false
    }
  }
  // 判斷付款方式
  let isPay = null
  if (brand.brand_type !== 2) {
    if (
      store.state.shop.shop_pay.bank.status_diner === 1 ||
      store.state.shop.shop_pay.cod.status_diner === 1 ||
      store.state.shop.shop_pay.credit.status_diner === 1 ||
      store.state.shop.shop_pay.linepay.status_diner === 1 ||
      store.state.shop.shop_pay.store.status_diner === 1
    ) {
      // console.log("商店付款方式開啟");
      isPay = true
      // next({ path: "/" });
      // return;
    }
    else {
      // console.log("商店付款方式關閉");
      isPay = false
    }
  } else if (brand.brand_type === 2) {
    if (
      store.state.shop.shop_pay.bank.status === 1 ||
      store.state.shop.shop_pay.cod.status === 1 ||
      store.state.shop.shop_pay.credit.status === 1 ||
      store.state.shop.shop_pay.linepay.status === 1 ||
      store.state.shop.shop_pay.store.status === 1
    ) {
      // console.log("商店付款方式開啟");
      isPay = true
      // next({ path: "/" });
      // return;
    }
    else {
      // console.log("商店付款方式關閉");
      isPay = false
    }
  }
  // 判斷商店是否到期
  let isExpiry = store.state.shop.expiry_date && moment().isBefore(store.state.shop.expiry_date) ? true : false
  
  // 進入關閉頁，商店有營業、有開啟付款方式、取貨方式全部都有開啟就會回到商品列表
  if (to.name === "Closed") {
    if (isOpen && isPickUp && isPay && isExpiry) next({ path: "/" });
  } 
  // 進入其他頁面，如果商店未營業、沒有開啟付款方式與取貨方式其中一樣，就會進入關閉頁
  else {
    if (!isOpen || !isPickUp || !isPay || !isExpiry) next({ path: "/closed" });
  }

  // console.log(store);
  if (to.name === undefined) {
    next("/");
    return;
  }

  next();
});

router.afterEach(() => {
  appendingInstance.close();
});
