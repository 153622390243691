import { getCities, getCityareas } from "@/utils/http.api";

class PostalService {
  getCities() {
    return getCities().then((res) => {
      if (res.status === 200) {
        return res.data.data;
      }
    });
  }
  getCityareas() {
    return getCityareas().then((res) => {
      if (res.status === 200) {
        return res.data.data;
      }
    });
  }
  async getCityCascader() {
    try {
      let cities = await this.getCities();
      let cityareas = await this.getCityareas();

      let cityareasByCityId = [];
      cityareas.forEach(function (cityarea) {
        if (typeof cityareasByCityId[cityarea.city_id] === "undefined")
          cityareasByCityId[cityarea.city_id] = [];
        cityareasByCityId[cityarea.city_id].push({
          label: cityarea.name,
          value: cityarea.id,
        });
      });

      let cascader = [];
      cities.forEach(function (city) {
        cascader.push({
          label: city.name,
          value: city.id,
          children: cityareasByCityId[city.id],
        });
      });

      return cascader;
    } catch (error) {
      console.log(error.message);
    }
  }
}

export default new PostalService();
