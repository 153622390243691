import CartsService from "@/services/carts.service";
import OrdersService from "@/services/orders.service";
import storage from "@/utils/storage";
import { number } from "yup";
import { getCartsRefresh } from "@u/http.api";

const state = () => ({
  carts: [],
  isLoading: false,
  isCreating: false,
  createdData: null,
  isUpdating: false,
  updatedData: null,
  isDeleting: false,
  deletedDate: null,
  isEmptyCarts: false,
  isCheckouting: false,
  checkoutData: null,
  kind: 1,
  soldOutProduct: null,
  errorProduct: null,
  errorCart: null,
});

const getters = {
  cartList: (state) => state.carts,
  isLoading: (state) => state.isLoading,
  isCreating: (state) => state.isCreating,
  createdData: (state) => state.createdData,
  isUpdating: (state) => state.isUpdating,
  updatedData: (state) => state.updatedData,
  isEmptyCarts: (state) => state.isEmptyCarts,
  isCheckouting: (state) => state.isCheckouting,
  checkoutData: (state) => state.checkoutData,
  errorProduct: (state) => state.errorProduct,
  errorCart: (state) => state.errorCart,
  soldOutProduct: (state) => state.soldOutProduct,
  cartTotal: (state) => {
    if (state.carts !== undefined) {
      return state.carts
        .reduce((acc, cart) => {
          return cart !== undefined ? cart.quantity * cart.price + acc : 0;
        }, 0)
        .toFixed(2);
    } else {
      return 0;
    }
  },
  cartQuantity: (state) => {
    return state.carts.reduce((acc, cart) => {
      return cart.quantity + acc;
    }, 0);
  },
  cartShippingIsCold: (state) => {
    return state.carts.find((data) => {
      return (
        data.product.shipping_type === 1 || data.product.shipping_type === 2
      );
    });
  },
};

const actions = {
  async getCarts({ commit, state }, query = {}) {
    commit("setIsLoading", true);
    commit("setErrorCart", false)
    if (query.kind !== null && query.kind !== undefined)
      commit("setKind", query.kind);
    await CartsService.getCarts(state.kind)
      .then((data) => {
        if (data) {
          commit("setCarts", data);
          commit("setIsLoading", false);
        } else {
          commit("setIsLoading", false);
        }
      })
      .catch((err) => {
        commit("setErrorCart", true);
        console.log("error", err);
        commit("setIsLoading", false);
      });
  },
  async getCartsRefresh({ commit, state }, query = {}) {
    commit("setIsLoading", true);
    if (query.kind !== null && query.kind !== undefined)
      commit("setKind", query.kind);
    await CartsService.getCartsRefresh(state.kind)
      .then((data) => {
        commit("setSoldOutProduct", data.error);
        if (data.data) {
          commit("setCarts", data.data);
          commit("setIsLoading", false);
        } else {
          commit("setIsLoading", false);
        }
      })
      .catch((err) => {
        commit("setErrorProduct", true)
        console.log("error", err);
        commit("setIsLoading", false);
      });
  },
  async addCartItem({ commit, state }, cart) {
    commit("setIsCreating", true);
    if (cart.kind !== null && cart.kind !== undefined)
      commit("setKind", cart.kind);
    await CartsService.addProduct(cart, state.kind)
      .then((data) => {
        commit("saveNewData", data, cart);
        commit("setIsCreating", false);
      })
      .catch((err) => {
        // console.log("error", err);
        commit("setIsCreating", false);
      });
  },
  async updateCartQuantity(
    { commit, state },
    cart = { id: number, quantity: number, kind: 1 }
  ) {
    commit("setIsUpdating", true);
    if (cart.kind !== null && cart.kind !== undefined)
      commit("setKind", cart.kind);
    await CartsService.updateCartsQuantity(cart.id, cart.quantity, state.kind)
      .then((data) => {
        if (cart.quantity === 0) {
          commit("setDeleteData", cart.id);
        } else {
          commit("saveUpdatedData", { data: data, quantity: cart.quantity });
        }
        commit("setIsUpdating", false);
      })
      .catch((err) => {
        console.log("error", err);
        commit("setIsUpdating", false);
      });
  },
  async deleteCart({ commit, state }, cart) {
    commit("setIsDeleting", true);
    let cart_id = 0;
    if (typeof cart == "object") {
      cart_id = cart;
    } else {
      if (cart.kind !== null && cart.kind !== undefined)
        commit("setKind", cart.kind);
    }

    await CartsService.deleteCarts(cart_id, state.kind)
      .then((data) => {
        commit("setDeleteData", cart_id);
        commit("saveDeletedData", data);
        commit("setIsDeleting", false);
      })
      .catch((err) => {
        console.log("error", err);
        commit("setIsDeleting", false);
      });
  },
  async isEmptyCarts({ commit, state }, query = {}) {
    commit("setIsLoading", true);
    if (query.kind !== null && query.kind !== undefined)
      commit("setKind", query.kind);
    await CartsService.isEmpty(state.kind)
      .then((data) => {
        commit("setIsEmptyCarts", data);
        commit("setIsLoading", false);
      })
      .catch((err) => {
        console.log("error", err);
        commit("setIsLoading", false);
      });
  },
  async saveCheckoutInfo({ commit }, data) {
    // console.log(data)
    storage.setItem("checkout_info", data, { type: "session" });
  },
  async saveAddressInfo({ commit }, data) {
    // console.log(data)
    storage.setItem("address_info", data, { type: "session" });
  },
  async checkoutOrder({ commit, state }, data) {
    // console.log(data);
    commit("setIsCheckouting", true);
    await OrdersService.addOrder(data)
      .then((data) => {
        // console.log(data)
        commit("saveCheckoutData", data);
        commit("setIsCheckouting", false);
      })
      .catch((err) => {
        console.log("error", err);
        commit("setIsCheckouting", false);
        throw err.data.error.message_detail
      });
  },
};

const mutations = {
  setCarts(state, data) {
    state.carts = data;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsCreating(state, isCreating) {
    state.isCreating = isCreating;
  },
  setKind(state, kind) {
    state.kind = kind;
  },
  saveNewData(state, data, cart) {
    state.carts.unshift(cart);
    state.createdData = data;
  },
  setIsUpdating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  saveUpdatedData(state, payload) {
    let index = state.carts.findIndex(
      (cart) => cart.id === payload.data.cart_id
    );
    if (index !== -1) state.carts[index].quantity = payload.quantity;
    state.updatedData = payload.data;
  },
  setIsDeleting(state, isDeleting) {
    state.isDeleting = isDeleting;
  },
  saveDeletedData(state, data) {
    state.deletedDate = data;
  },
  setDeleteData: (state, cart_id) => {
    state.carts = state.carts.filter((cart) => cart.id !== cart_id);
  },
  setIsEmptyCarts(state, isEmptyCarts) {
    state.isEmptyCarts = isEmptyCarts;
  },
  setIsCheckouting(state, isCheckouting) {
    state.isCheckouting = isCheckouting;
  },
  saveCheckoutData(state, data) {
    state.checkoutData = data;
    state.carts = [];
  },
  setErrorProduct(state, errorProduct) {
    state.errorProduct = errorProduct;
  },
  setSoldOutProduct(state, soldOutProduct) {
    state.soldOutProduct = soldOutProduct;
  },
  setErrorCart(state, error) {
    state.errorCart = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
