import axios from "axios";
import ElMessage from "element-plus";
import LineService from "@/services/line.service";
import qs from "qs";
import router from "@/router";
import store from "@/store/index";
import storage from "@/utils/storage";
storage.config = { type: "session" };

// 定时器数组，一般我们都是等请求时间超过1秒后，才出现loading状态，如果一开始就提示，用户体验也不是很好
let timers = [],
  timer = null;

// 聲明一個 Map 用于存儲每个請求的標示 和 取消函数
const pending = new Map();
/**
 * 新增請求
 * @param {Object} config
 */
const addPending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join("&");
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pending.has(url)) {
        pending.set(url, cancel);
      }
    });
};
/**
 * 移除請求
 * @param {Object} config
 */
const removePending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join("&");
  if (pending.has(url)) {
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};

/**
 * 清空 pending 中的請求（在路由跳轉時使用）
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};

let statusTimer = true;

const showStatus = (status) => {
  let message = "";
  switch (status) {
    case 400:
      message = "請求錯誤(400)";
      break;
    case 401:
      message = "未授權，請重新登入(401)";
      if (statusTimer) {
        statusTimer = false;
        LineService.logout();
        sessionStorage.clear();
        alert("登入權限過期或失效，請重新整理頁面");
        setTimeout(() => {
          statusTimer = true;
        }, 5000);
      }
      break;
    case 403:
      message = "拒絕訪問(403)";
      break;
    case 404:
      message = "請求出錯，找不到頁面(404)";
      break;
    case 408:
      message = "請求超时(408)";
      break;
    case 500:
      message = "服務器錯誤(500)";
      // router.push("/closed");
      break;
    case 501:
      message = "服務未實現(501)";
      break;
    case 502:
      message = "網路錯誤(502)";
      break;
    case 503:
      message = "服務不可用(503)";
      break;
    case 504:
      message = "網路超時(504)";
      break;
    case 505:
      message = "HTTP版本不受支持(505)";
      break;
    default:
      message = `連接出错(${status})!`;
  }
  return `${message}，請檢查網路或聯絡網站管理员！`;
};

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
});

// http request 欄截
instance.interceptors.request.use(
  (config) => {
    // removePending(config);
    // addPending(config);
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
      "Access-Control-Max-Age": 86400,
      Accept: "application/json",
      get: { "Content-Type": "application/json;charset=utf-8" },
      post: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      put: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    };
    config.data = qs.stringify(config.data);
    // config.withCredentials = true;
    config.validateStatus = function (status) {
      return true;
    };

    let token = storage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // 每次请求开始前 都开启延迟定时器，并记录在数组中，便于响应回来后 清除
    // store.dispatch("basic/changeAppending", true);
    // timer = setTimeout(() => {
    //   return !store.state.basic.isAppending;
    //   // !store.state.basic.isAppending &&
    //   //   store.commit("basic/changeAppending", true);
    // }, 1000);
    // timers.push(timer);

    return config;
  },
  (error) => {
    error.data = {};
    error.data.msg = "服務異常，請洽網站管理員！";
    return Promise.resolve(error);
  }
);

// 異常處理
instance.interceptors.response.use(
  (response) => {
    // removePending(response);
    // const status = response.status;
    // let msg = "";
    // if (status < 200 || status >= 300) {
    //   msg = showStatus(status);
    //   if (typeof response.data === "string") {
    //     response.data = { msg };
    //   } else {
    //     response.data.msg = msg;
    //   }
    // }
    const status = response?.status;
    showStatus(status);

    // clearAllTimer(); //清除所有追蹤
    // store.dispatch("basic/changeAppending", false).then().catch();
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    showStatus(status);
    // if (axios.isCancel(error)) {
    //   console.log("repeated request: " + error.message);
    // } else {
    //   // handle error code
    //   error.data = {};
    //   error.data.msg = "請求超時或服務異常，請檢察網路或聯絡網站管理員！";
    //   ElMessage.error(error.data.msg);
    // }
    // clearAllTimer(); //清除所有追蹤
    // store.dispatch("basic/changeAppending", false).then().catch();
    return Promise.reject(error);
  }
);

//get請求
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//post請求
export function post(url, data = {}, params = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data, { params: params }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

//delete 請求
export function remove(url, data = {}, params = {}) {
  return new Promise((resolve, reject) => {
    instance.delete(url, data, { params: params }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

//put 請求
export function put(url, data = {}, params = {}) {
  return new Promise((resolve, reject) => {
    instance.put(url, data, { params: params }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function clearAllTimer() {
  timers.forEach((item) => {
    clearTimeout(item);
  });
  timers = [];
}
