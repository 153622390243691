import { createApp } from "vue";
import 'bootstrap';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import ElementPlus from "element-plus";
import "./theme/element/index.css";
import "dayjs/locale/zh-tw";
import locale from "element-plus/lib/locale/lang/zh-tw";
import "@/permission"; // permission control
import VConsole from "vconsole";

if (
  process.env.NODE_ENV === "development" &&
  process.env.VUE_APP_NO_VCONSOLE === "true"
) {
  vConsole = new VConsole();
}
if (process.env.NODE_ENV === "development") {
  const { mockXHR } = require("../mock");
  mockXHR();
}

// const vConsole = new VConsole();
const app = createApp(App);

app.use(store);
app.use(router);
app.use(i18n);
// app.use(vConsole)
app.use(ElementPlus, { locale });
app.mount("#app");
