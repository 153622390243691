module.exports = [
  {
    type: "get",
    url: "/carts",
    response: () => {
      return {
        data: [
          {
            addition_product_ids: "[]",
            addition_products: "",
            id: 1439,
            price: 333.0,
            product: {
              id: 104,
              image_url: null,
              is_available: 1,
              kind: 1,
              price: 333.0,
              shipping_type: null,
              stock: 1,
              stock_unlimited: 1,
              summary: "bbb",
              title: "bbb",
              is_vip: 0,
              vip_price: 390,
            },
            quantity: 1,
          },
        ],
      };
    },
  },
];
