import ProductsService from "@/services/products.service";
import storage from "@/utils/storage";

const state = () => ({
  products: [],
  productsPaginatedData: null,
  product: null,
  isLoading: false,
  isNoData: null,
});

const getters = {
  productList: (state) => state.products,
  productsPaginatedData: (state) => state.productsPaginatedData,
  product: (state) => state.product,
  isLoading: (state) => state.isLoading,
  isNoData: (state) => state.isNoData,
};

const actions = {
  async getProductsByCategoryId({ commit }, query = {}) {
    let category_id = "";
    let kind = 1;
    let page = null;
    let limit = 500;
    if (query !== null) {
      if (query.category_id !== null && query.category_id !== undefined)
        category_id = query.category_id;
      if (query.kind !== null && query.kind !== undefined) kind = query.kind;
      if (query.page !== null && query.page !== undefined) page = query.page;
      if (query.limit !== "" && query.limit !== undefined) limit = query.limit;
    }
    commit("setIsLoading", true);
    if (category_id !== undefined) {
      await ProductsService.getProductsByCategoryId(
        category_id,
        kind,
        page,
        limit
      )
        .then((data) => {
          if (data.data) {
            const products = data.data;
            commit("setProducts", products);
            commit("setProductsPaginated", data);
            commit("setIsLoading", false);
            commit("setIsNoData", false);
          } else {
            commit("setIsLoading", false);
            commit("setIsNoData", true);
          }
        })
        .catch((err) => {
          console.log("error", err);
          commit("setIsLoading", false);
          commit("setIsNoData", true);
        });
    }
  },

  async addProductsByCategoryId({ commit }, query = {}) {
    let category_id = "";
    let kind = 1;
    let page = null;
    let limit = 500;
    if (query !== null) {
      if (query.category_id !== null && query.category_id !== undefined)
        category_id = query.category_id;
      if (query.kind !== null && query.kind !== undefined) kind = query.kind;
      if (query.page !== null && query.page !== undefined) page = query.page;
      if (query.limit !== "" && query.limit !== undefined) limit = query.limit;
      
    }
    const currentId = storage.getItem("shop_category_id");
    if (currentId) category_id = currentId;
    // commit("setIsLoading", true);
    if (category_id !== undefined) {
      await ProductsService.getProductsByCategoryId(
        category_id,
        kind,
        page,
        limit
      ).then((data) => {
        if (data.data) {
          const products = data.data;
          commit("addProducts", products);
          commit("addProductsPaginated", data);
          // commit("setIsLoading", false);
          // commit("setIsNoData", false);
        }
      });
    }
  },
  // 取得商品詳細資料
  async fetchDetailProduct({ commit }, product_id) {
    commit("setIsLoading", true);
    await ProductsService.getProduct(product_id)
      .then((data) => {
        // console.log(data)
        commit("setProductDetail", data);
        commit("setIsLoading", false);
      })
      .catch((err) => {
        commit("setIsLoading", false);
        // return Promise.reject(err);
      });
  },
};

const mutations = {
  setProducts(state, data) {
    state.products = data;
  },
  addProducts(state, data) {
    state.products.push(...data);
  },
  addProductsPaginated: (state, productsPaginatedData) => {
    state.productsPaginatedData.pagination = productsPaginatedData?.pagination;
  },
  setProductsPaginated: (state, productsPaginatedData) => {
    state.productsPaginatedData = productsPaginatedData;
  },
  // 將商品詳細資料傳給state
  setProductDetail: (state, product) => {
    state.product = product;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsNoData(state, isNoData) {
    state.isNoData = isNoData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
