import storage from "@/utils/storage";
import {
  getProfile,
  putProfile,
  getHomepageProfile,
  getPointProfile,
} from "@/utils/http.api";
import { format, parseISO } from "date-fns";

class MemberService {
  getProfile() {
    return getProfile().then((res) => {
      // console.log(res.data)
      if (res.status === 200) {
        if (res.data.data.birthday) {
          res.data.data.birthday = parseISO(res.data.data.birthday);
        }
        return res.data.data;
      } else {
        return Promise.reject(res.data);
      }
    });
  }
  updateProfile(data) {
    // console.log(data)
    if (data.birthday !== "") {
      data.birthday = format(data.birthday, "yyyy-MM-dd");
    }
    return putProfile(data).then((res) => {
      if (res.status === 200 || res.status === 400) {
        return res.data;
      }
    });
  }
  getHompageProfile() {
    return getHomepageProfile().then((res) => {
      return res.data.data;
    });
  }
  getPointProfile(parmas) {
    return getPointProfile(parmas).then((res) => {
      return res.data;
    });
  }
}

export default new MemberService();
