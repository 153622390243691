import ProductsService from "@/services/products.service";
import storage from "@/utils/storage";

const state = {
  isLoading: false,
  categories: [],
  defaultCategoryId: 0,
  categoryName: null,
};

const getters = {
  isLoading: (state) => state.isLoading,
  categories: (state) => state.categories,
  defaultCategoryId: (state) => {
    let result = null;
    if (state.defaultCategoryId == 0) {
      const category = state.categories?.find((value, index) => {
        return index === 0;
      });
      result = category !== undefined ? category.id : state.defaultCategoryId;
    } else {
      result = state.defaultCategoryId;
    }
    return result;
  },
  categoryName: (state) => state.categoryName,
};

const actions = {
  getCategories({ commit }, kind = 1) {
    commit("setIsLoading", true);
    ProductsService.getCategories(kind)
      .then((data) => {
        commit("setCategories", data);
        commit("setIsLoading", false);
      })
      .catch((err) => {
        console.log("error", err);
        commit("setIsLoading", false);
      });
  },
  getDefaultCategoryId({ commit, getters }) {
    commit("setDefaultCategoryId", getters.defaultCategoryId);
  },
  setDefaultCategoryId({ commit, getters }, category_id) {
    commit("setDefaultCategoryId", category_id);
  },
  getCategoryName({ commit }, category_id) {
    const category = state.categories.find((value) => {
      return value.id == category_id;
    });
    return category !== undefined
      ? commit("setCategoryName", category.name)
      : commit("setCategoryName", "");
  },
};

const mutations = {
  setCategories(state, data) {
    state.categories = data;
  },
  setDefaultCategoryId(state, category_id) {
    state.defaultCategoryId = category_id;
  },
  setCategoryName(state, name) {
    state.categoryName = name;
    storage.setItem("category_name", name, { type: "session" });
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
