import {
  getCarts,
  postCarts,
  putCarts,
  removeCarts,
  getCartsIsEmpty,
  getCartsRefresh,
} from "@/utils/http.api";

class CartsService {
  async getCarts(kind) {
    const response = await getCarts({ kind: kind })
    // 目前沒有處理到其他錯誤
    if (response.status === 200 || response.status === 400) {
      const containsKey = (obj, key) => Object.keys(obj).includes(key);
      const isError = containsKey(response.data, "error");
      return isError, response.data.data;
    } else {
      throw new Error(response.data.error.message);
    }
  }
  async getCartsRefresh(kind) {
    const response = await getCartsRefresh({ kind: kind })
    // 目前沒有處理到其他錯誤
    if (response.status === 200 || response.status === 400) {
        let isError = response.data?.error_product;
        const containsKey = (obj, key) => Object.keys(obj).includes(key);
        if (response.status === 400 && containsKey(response.data, "error")) {
            throw new Error(response.data.error.message);
        }
        return { error: isError, data: response.data.data };
    } else {
      throw new Error(response.data.error.message);
    }
  }
  addProduct(data, kind) {
    // console.log(data)
    data.addition_product_ids = JSON.stringify(data.addition_product_ids);
    return postCarts(data, { kind: kind }).then((res) => {
      if (res.status === 200 || res.status === 400) {
        return res.data;
      }
    });
  }
  updateCartsQuantity(cart_id, quantity, kind) {
    return putCarts({ quantity: quantity }, cart_id, { kind: kind }).then(
      (res) => {
        if (res.status === 200 || res.status === 400) {
          return res.data;
        }
      }
    );
  }
  deleteCarts(cart_id, kind) {
    return removeCarts(cart_id, { kind: kind }).then((res) => {
      if (res.status === 200 || res.status === 400) {
        return res.data;
      }
    });
  }
  isEmpty(kind) {
    return getCartsIsEmpty({ kind: kind }).then((res) => {
      if (res.status === 200) {
        return res.data.is_empty;
      }
    });
  }
}

export default new CartsService();
