const Index = () => import("@/views/index/index.vue");
const Empty = () => import("@/views/index/empty.vue");
const StoreInfo = () => import("@/views/Store");
const Point = () => import("@/views/point/index.vue");


export default [
  {
    path: "/",
    component: Empty,
  },
  {
    path: "/member",
    name: "Index",
    component: Index,
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/point",
    name: "Point",
    component: Point,
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/store",
    name: "StoreInfo",
    component: StoreInfo,
    meta: {
      needLogin: true,
    },
  },
];
