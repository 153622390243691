module.exports = [
  {
    type: "get",
    url: "/profile/homepage",
    response: () => {
      // return paymentType[body.payment_type]
      return {
        data: {
          id: 1,
          avatar: "https://xxx.omos.tw/profile/xxx/xxx.png",
          name: "流川楓",
          is_vip: "0",
          points: 999,
        },
      };
    },
  },
];
