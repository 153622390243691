const PaymentDone = () => import("@/views/shops/CheckoutFinish");

export default [
  {
    path: "/payment/done",
    name: "PaymentDone",
    component: PaymentDone,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
];
