module.exports = [
  {
    type: "get",
    url: "/products",
    response: () => {
      return {
        data: [
          {
            category_id: 112,
            image_url: null,
            is_available: 0,
            kind: 1,
            price: 55.0,
            product_id: 162,
            sort: 9999,
            stock: 0,
            stock_unlimited: 1,
            summary: "\u9023\u9396\u96de\u6392",
            title: "\u963f\u4eae\u96de\u6392",
            is_vip: 0,
          },
          {
            category_id: 112,
            image_url: null,
            is_available: 0,
            kind: 1,
            price: 11.0,
            product_id: 107,
            sort: 9999,
            stock: 0,
            stock_unlimited: 1,
            summary: "eee test\n",
            title: "eee",
            is_vip: 1,
            vip_price: 390,
          },
          {
            category_id: 112,
            image_url: null,
            is_available: 1,
            kind: 1,
            price: 333.0,
            product_id: 104,
            sort: 9999,
            stock: 1,
            stock_unlimited: 1,
            summary: "bbb",
            title: "bbb",
            is_vip: 0,
            vip_price: 390,
          },
        ],
        pagination: {
          current_page: 1,
          from: null,
          last_page: 1,
          pre_page: 20,
          to: null,
          total: 3,
        },
      };
    },
  },
];
