/*
 * localstorage的實現
 * */
export default {
  getItem(key) {
    let item = localStorage.getItem(key);
    let result = /^[{[].*[}\]]$/g.test(item); // 這點要判斷是字串還是物件
    if (result) {
      return JSON.parse(item);
    } else {
      return item;
    }
  },
  setItem(key, value) {
    if (typeof value === "string") {
      localStorage.setItem(key, value);
    } else {
      let item = JSON.stringify(value);
      localStorage.setItem(key, item);
    }
  },
  removeItem(key) {
    localStorage.removeItem(key);
  },
  getAll() {},
  clear() {
    localStorage.clear();
  },
  key(n) {},
  forEach(cb) {},
  has(key) {},
  deleteAllExpires() {},
  getMaxSpace() {
    if (!window.localStorage) {
      console.log(this.$t("STORAGE.LOCAL.BROWSER_NOT_SUPPORT"));
    }
    var test = "0123456789";
    var add = function (num) {
      if (num.length == 10240) {
        test = num;
        return;
      }
      add(num);
    };
    add(test);
    var sum = test;
    var show = setInterval(function () {
      sum = test;
      try {
        window.localStorage.removeItem("test");
        window.localStorage.setItem("test", sum);
      } catch (e) {
        console.log(
          this.$t("STORAGE.LOCAL.OVER_SIZE", { sum: sum.length / 1024 })
        );
        clearInterval(show);
      }
    }, 0.1);
  },
  getUsedSpace() {
    // 獲取使用了的localstorage的空間
    if (!window.localStorage) {
      console.log(this.$t("STORAGE.LOCAL.BROWSER_NOT_SUPPORT"));
    }
    var size = 0;
    for (let item in window.localStorage) {
      if (Object.prototype.hasOwnProperty.call(window.localStorage, item)) {
        size = window.localStorage.getItem(item).length;
      }
    }
    console.log(
      this.$t("STORAGE.LOCAL.USED_SIZE", { sum: (size / 1024).toFixed(2) })
    );
  },
};
