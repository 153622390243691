const Home = () => import("@/views/Home");

export default [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      needLogin: true,
      shop_type: "diner",
    },
  },
  {
    path: "/home/:id",
    name: "Categories",
    component: Home,
    meta: {
      needLogin: true,
      shop_type: "diner",
    },
  },
];
