import { put, get, post, remove } from "@/utils/http";
export const getLineID = (params) => get("/liff-id", params);
export const getCities = () => get("/cities");
export const getCityareas = () => get("/cityareas");
export const getBrands = (params) => get("/brands", params);
export const postLogin = (data) => post("/login", data);
export const getProfile = () => get("/profile");
export const putProfile = (data) => put("/profile", data);
export const getCategories = (params) => get("/categories", params);
export const getCategories2 = (params) => get("/categories2", params);
export const getProducts = (params) => get("/products", params);
export const getProducts2 = (params) => get("/products2", params);
export const getProduct = (product_id) => get(`/products/${product_id}`);
export const getProduct2 = (product_id, params) =>
  get(`/products2/${product_id}`, params);
export const getCarts = (params) => get("/carts", params);
export const getCartsRefresh = (params) => get("/carts/refresh", params);
export const postCarts = (data, params) => post("/carts", data, params);
export const putCarts = (data, cart_id, params) =>
  put(`/carts/${cart_id}`, data, params);
export const removeCarts = (cart_id, params) =>
  remove(`/carts/${cart_id}`, {}, params);
export const getCartsIsEmpty = (params) => get("/carts/is_empty", params);
export const getOrders = (params) => get("/orders", params);
export const postOrders = (data, params) => post("/orders", data, params);
export const getOrder = (order_id) => get(`/orders/${order_id}`);
export const cancelOrder = (order_id) => get(`/orders/${order_id}/cancel`);
export const getOrderBusinessCount = () => get(`/orders/business_count`);
export const postImages = (data) => post("/images", data);

export const getHomepageProfile = () => get("/profile/homepage");
export const getPointProfile = (params) => get("/profile/points", params);
export const getTables = () => get("/tables");

// 優惠卷
export const getCoupons = (params) => get("/member-coupons", params);
export const getCouponsHistory = (params) =>
  get("/member-coupons/history", params);
export const getCouponsDetail = (id) => get(`/member-coupons/${id}`);
export const getCooperations = (params) => get("/cooperations", params);
export const getCooperationCouponDetail = (id) =>
  get(`/cooperation-coupons/${id}`);

// 購物車優惠卷
export const getCartCoupon = (params) => get("/carts/coupons", params);
