const Carts = () => import("@/views/Carts");
const Checkout = () => import("@/views/Checkout");
const CheckoutConfirm = () => import("@/views/CheckoutConfirm");
const CheckoutFinish = () => import("@/views/CheckoutFinish");

export default [
  {
    path: "/carts",
    name: "Carts",
    component: Carts,
    meta: {
      needLogin: true,
      shop_type: "diner",
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      needLogin: true,
      shop_type: "diner",
    },
  },
  {
    path: "/checkout_confirm",
    name: "CheckoutConfirm",
    component: CheckoutConfirm,
    meta: {
      needLogin: true,
      shop_type: "diner",
    },
  },
  {
    path: "/checkout-finish",
    name: "CheckoutFinish",
    component: CheckoutFinish,
    meta: {
      needLogin: true,
      shop_type: "diner",
    },
  },
];
