const ShopCategory = () => import("@/views/shops/Categories");
const ShopProduct = () => import("@/views/shops/Product");
const ShopCarts = () => import("@/views/shops/Carts");
const ShopCheckout = () => import("@/views/shops/Checkout");
const ShopCheckoutConfirm = () => import("@/views/shops/CheckoutConfirm");
const ShopCheckoutFinish = () => import("@/views/shops/CheckoutFinish");
const ShopOrders = () => import("@/views/shops/Orders");
const ShopOrder = () => import("@/views/shops/Order");

export default [
  {
    path: "/shop",
    name: "ShopHome2",
    component: ShopCategory,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
  {
    path: "/shop/category",
    name: "ShopHome",
    component: ShopCategory,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
  {
    path: "/shop/category/:id",
    name: "ShopCategories",
    component: ShopCategory,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
  {
    path: "/shop/product/:id",
    name: "ShopProduct",
    component: ShopProduct,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
  {
    path: "/shop/carts",
    name: "ShopCarts",
    component: ShopCarts,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
  {
    path: "/shop/checkout",
    name: "ShopCheckout",
    component: ShopCheckout,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
  {
    path: "/shop/checkout_confirm",
    name: "ShopCheckoutConfirm",
    component: ShopCheckoutConfirm,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
  {
    path: "/shop/checkout-finish",
    name: "ShopCheckoutFinish",
    component: ShopCheckoutFinish,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
  {
    path: "/shop/orders",
    name: "ShopOrders",
    component: ShopOrders,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
  {
    path: "/shop/orders/:id",
    name: "ShopOrder",
    component: ShopOrder,
    meta: {
      needLogin: true,
      shop_type: "shop",
    },
  },
];
