const Product = () => import("@/views/Product");

export default [
  {
    path: "/product/:id",
    name: "Product",
    component: Product,
    meta: {
      needLogin: true,
      shop_type: "diner",
    },
  },
];
