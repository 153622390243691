<template>
  <main role="main">
    <div class="container-fluid p-0">
      <router-view :isLoading="isLoading" @onLoading="onLoading"></router-view>
    </div>
  </main>
</template>

<style lang="scss">
@import "./src/assets/scss/style.scss";
:root {
  /*--使用RGB值--*/
  --main-color-rgb: 250, 130, 27;
  --main-color: rgb(var(--main-color-rgb));
  --main-color-opacity: rgba(var(--main-color-rgb), 0.1);
  --minor-color-rgb: 224, 164, 113;
  --minor-color: rgb(var(--minor-color-rgb));
}
</style>
<script>
import { ref, computed, provide, onUpdated, onUnmounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import _ from "lodash";

export default {
  name: "App",
  setup() {
    console.log("app 開始～～～");
    const store = useStore();
    const isLoading = ref(true);
    const onLoading = (type) => {
      isLoading.value = type;
    };
    const route = useRoute();

    store.dispatch("shop/getLoad");
    const shop_name = computed(() => store.getters["shop/brand_name"]);
    const shop_logo = computed(() => store.getters["shop/brand_logo"]);
    const shop_line_name = computed(
      () => store.getters["shop/brand_line_name"]
    );
    const shop_line_id = computed(() => store.getters["shop/brand_line_id"]);
    const shop_city = computed(() => store.getters["shop/brand_city"]);
    const shop_cityarea = computed(() => store.getters["shop/brand_cityarea"]);
    const shop_address = computed(() => store.getters["shop/brand_address"]);
    const shop_phone = computed(() => store.getters["shop/brand_phone"]);
    const shop_other = computed(() => store.getters["shop/brand_other"]);
    const shop_banners = computed(() => store.getters["shop/brand_banners"]);
    const title_name = computed(() => store.getters["shop/title_name"]);
    const tax_id = computed(() => store.getters["shop/tax_id"]);
    //Add By Bryant
    const shop_dm_mode_setting = computed(
      () => store.getters["shop/shop_dm_mode_setting"]
    );
    const shop_open_news = computed(() => store.getters["shop/open_hours"]);
    const shop_news = computed(() => store.getters["shop/shop_news"]);
    const shop_setting = computed(() => store.getters["shop/setting"]);
    const shop_type = computed(() => store.getters["shop/store_type"]);
    const shop_closed = computed(() => store.getters["shop/closed"]);
    const shop_shipping = computed(() => store.getters["shop/shop_shipping"]);
    const shop_pay = computed(() => store.getters["shop/shop_pay"]);
    const shop_is_invoice = computed(() => store.getters["shop/is_invoice"]);

    provide("shop_name", shop_name);
    provide("shop_logo", shop_logo);
    provide("shop_line_name", shop_line_name);
    provide("shop_line_id", shop_line_id);
    provide("shop_city", shop_city);
    provide("shop_cityarea", shop_cityarea);
    provide("shop_address", shop_address);
    provide("shop_phone", shop_phone);
    provide("shop_other", shop_other);
    provide("shop_banners", shop_banners);
    //Add By Bryant
    provide("shop_dm_mode_setting", shop_dm_mode_setting);
    provide("shop_open_news", shop_open_news);
    provide("shop_news", shop_news);
    provide("shop_setting", shop_setting);
    provide("shop_type", shop_type);
    provide("shop_closed", shop_closed);
    provide("shop_shipping", shop_shipping);
    provide("shop_pay", shop_pay);
    provide("shop_is_invoice", shop_is_invoice);
    provide("title_name", title_name);
    provide("tax_id", tax_id);

    return {
      isLoading,
      onLoading,
      shop_name,
      shop_logo,
    };
  },
};
</script>
