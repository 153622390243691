const Closed = () => import("@/components/Closed");

export default [
  {
    path: "/closed",
    name: "Closed",
    component: Closed,
    meta: {
      needLogin: false,
      isInClientNoLogin: true,
    },
  },
];
