const Orders = () => import("@/views/Orders");
const Order = () => import("@/views/Order");

export default [
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      needLogin: true,
      shop_type: "diner",
    },
  },
  {
    path: "/orders/:id",
    name: "Order",
    component: Order,
    meta: {
      needLogin: true,
      shop_type: "diner",
    },
  },
];
