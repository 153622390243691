import storage from "@/utils/storage";
import { postLogin } from "@/utils/http.api";

class AuthService {
  lineLogin(member) {
    return postLogin({
      host: member.host,
      token: member.token,
      cooperation_id: member.cooperation_id,
      coupon_id: member.coupon_id,
    }).then((response) => {
      if (response.status === 200) {
        storage.setItem("token", response.data.token, {
          type: "session",
        });
        return response.data;
      } else if (response.status == 400) {
        this.logout();
        this.lineLogout();
      }
      Promise.reject("login fail");
    });
  }

  logout() {
    storage.removeItem("token", { type: "session" });
  }

  lineLogout() {
    storage.setItem("lineExpired", true, { type: "session" });
  }
}

export default new AuthService();
