import {
  getOrders,
  getOrder,
  cancelOrder,
  postOrders,
  getOrderBusinessCount,
} from "@/utils/http.api";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

class OrdersService {
  getOrders(type = "diner", page = 1, limit = 50) {
    return getOrders({
      type: type,
      page: page,
      limit: limit,
    }).then((res) => {
      if (res.status === 200 || res.status === 400) {
        const containsKey = (obj, key) => Object.keys(obj).includes(key);
        const isError = containsKey(res.data, "error");
        return isError, res.data;
      }
    });
  }
  getOrder(order_id) {
    return getOrder(order_id).then((res) => {
      if (res.status === 200) {
        if (
          res.data.data.order_diner &&
          res.data.data.order_diner.meal_at !== null
        ) {
          const meal_format = [0, 1, 2].includes(res.data.data.type)
            ? "yyyy-MM-dd HH:mm:ss"
            : "yyyy-MM-dd";
          res.data.data.order_diner.meal_at = format(
            zonedTimeToUtc(res.data.data.order_diner.meal_at, "UTC"),
            meal_format
          );
        }
        res.data.data.created_at = format(
          zonedTimeToUtc(res.data.data.created_at, "UTC"),
          "Y-MM-dd HH:mm"
        );
        return res.data.data;
      } else {
        return Promise.reject(res.data);
      }
    });
  }
  cancelOrder(order_id) {
    return cancelOrder(order_id).then((res) => {
      if (res.status === 200 || res.status === 400) {
        return res.data;
      }
    });
  }
  async addOrder(data) {
    // console.log(data)
    const pickDate = data.pickDate
      ? data.pickDate
      : format(new Date(), "Y-MM-dd");
    let meal_at = pickDate + " " + data.meal_at;
    let params = {};
    let payload = {
      meal_at: format(utcToZonedTime(meal_at, "UTC"), "yyyy-MM-dd HH:mm:ss"),
      memo: data.comment,
      pay_id: data.pay_id 
    };
    if (["order", "here", "togo", "delivery_d"].includes(data.type)) {
      if (data.type === "here") {
        payload.type = data.type = 0;
        payload.people = JSON.stringify(data.people);
      }
      if (data.type === "togo") payload.type = data.type = 1;
      if (data.type === "order") {
        payload.type = data.type = 6;
        payload.people = JSON.stringify(data.people);
        if(data.table1_Id){
          payload.table1_id = data.table1_Id
        }
        if(data.table2_Id){
          payload.table2_id = data.table2_Id
        }
      }
      if (data.type === "delivery_d") {
        payload.type = data.type = 2;
        payload.receiver_name = data.receiver_name;
        payload.receiver_phone = data.receiver_phone;
        payload.receiver_city_id = data.receiver_city_id;
        payload.receiver_cityarea_id = data.receiver_cityarea_id;
        payload.receiver_address = data.receiver_address;
        payload.buyer_name = data.buyer_name;
        payload.buyer_phone = data.buyer_phone;
      } else {
        payload.buyer_name = data.buyer_name;
        payload.buyer_phone = data.buyer_phone;
      }
    } else if (["instore", "delivery"].includes(data.type)) {
      payload = data;
      payload.memo = data.comment;
      meal_at = data.pick_date + " " + data.pick_time;
      payload.meal_at = format(
        utcToZonedTime(meal_at, "UTC"),
        "yyyy-MM-dd HH:mm:ss"
      );
      if (data.type == "instore") payload.type = 3;
      if (data.type == "delivery") payload.type = 4;
      params = { kind: 2 };
    }
    // 電子發票
    payload.invoice_type = data.invoice.type;
    if (payload.invoice_type == 2) {
      payload.invoice_info = JSON.stringify({
        vat_number: data.invoice.vat_number,
      });
    } else if (payload.invoice_type == 3) {
      payload.invoice_info = JSON.stringify({
        carrier_id: data.invoice.carrier_id,
      });
    } else if (payload.invoice_type == 4) {
      payload.invoice_info = JSON.stringify({
        carrier_id: data.invoice.npo_ban,
      });
    }
    // 點數
    if(data.point === '' || data.point === null){
      data.point = 0
    }
    payload.point = data.point;
    if (data.member_coupon_id !== undefined) {
      payload.member_coupon_id = data.member_coupon_id;
    }
    // console.log(payload)
    
    const res = await postOrders(payload, params)
    if (res.status === 200 || res.status === 400) {
      return res.data;
    } 
    throw res;
  }
  getBusinessCount() {
    return getOrderBusinessCount().then((res) => {
      if (res.status === 200 || res.status === 400) {
        const containsKey = (obj, key) => Object.keys(obj).includes(key);
        const isError = containsKey(res.data, "error");
        return isError, res.data;
      }
    });
  }
}

export default new OrdersService();
