import storage from "@/utils/storage";
import liff from "@line/liff";
import _ from "lodash";
import AuthService from "@/services/auth.service";
import queryString from "querystring";
let os = null // 取得系統作業環境

export class LineService {
  async login() {
    // console.log('即將進入登入')
    if (storage.getItem("lineExpired", { type: "session" }))
      await this.logout();
    await this.init();
    // console.log('初始化完成')
    return await this.loginPromist();
  }

  async logout() {
    await this.init();
    if (liff.isLoggedIn()) {
      liff.logout();
      storage.removeItem("token");
      storage.removeItem("lineExpired");
    }
    return true;
  }

  async init() {
    // console.log('即將初始化')
    try {
      await liff.init({
        liffId: storage.getItem("liff-id", { type: "session" }),
      });
      // console.log('初始化')
      os = await liff.getOS()
      // console.log('os', os)
      storage.setItem('os', os)
      const { type } = liff.getContext()
      storage.setItem('environmentType', type)
    } catch (error) {
      console.log(error)
      return error;
    }
  }

  isInClient() {
    return liff.isInClient();
  }

  async loginPromist() {
    // console.log('即將登入')
    if (!liff.isLoggedIn()) {
      liff.login({
        state: Math.random().toString(32).substring(2),
        redirectUri: location.href,
      });
      // 由於用戶尚未登入 LINE
      // 所以透過一個不會結束的 Promise 避免程式繼續執行下去
      await new Promise((resolve) => { });
    }
    const {
      code,
      liffClientId,
      state,
      liffRedirectUri,
      cooperation_id,
      coupon_id,
    } = queryString.parse(window.location.search.replace("?", ""));
    return await AuthService.lineLogin({
      host: window.location.host,
      token: liff.getIDToken(),
      cooperation_id: cooperation_id,
      coupon_id: coupon_id,
    })
      .then(async (res) => {
        if (liffRedirectUri === window.location.origin) return "/";
        if (await this.waitAddFriend()) {
          if (res?.cooperation_coupon_data?.member_coupon_id) {
            return `/coupon-detail/${res?.cooperation_coupon_data?.member_coupon_id}`;
          }
          return liffRedirectUri;
        }
        return "/add-friend";
      })
      .catch((err) => {
        console.log("登入錯誤了");
        console.log(err);
      });
  }

  async isFriend() {
    return _.get(
      await liff.getFriendship().catch((err) => {
        liff.logout();
        storage.removeItem("token");
        storage.removeItem("lineExpired");
      }),
      "friendFlag",
      false
    );
  }

  async waitAddFriend() {
    if (await this.isFriend()) return Promise.resolve(true);
    return Promise.resolve(false);
  }

  async shareFriend(msg) {
    this.init()
    if (!os) {
      navigator.clipboard?.writeText && navigator.clipboard.writeText(msg[0].text);
      return {
        status: 'copied',
        os
      }
    } else {
      try {
        return {
          ...await liff
            .shareTargetPicker(msg, {
              isMultiple: true, // 設定訊息是否可以傳送給多個好友
            }),
          os
        }
      } catch (err) {
        if (err?.code === 'EXCEPTION_IN_SUBWINDOW' || err?.code === "FORBIDDEN") {
          return {
            status: 'closed',
            os
          }
        }
      }
    }
  }
  async getPermanentLink() {
    await this.init()
    return liff.permanentLink.createUrl()
  }
}

export default new LineService();
