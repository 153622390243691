import OrdersService from "@/services/orders.service";
import { getOrderBusinessCount } from "@u/http.api";

const state = () => ({
  orders: [],
  ordersPaginatedData: null,
  order: null,
  otherPriceOfOrder: null,
  productQuantityOfOrder: null,
  buyerOfOrder: null,
  receiverOfOrder: null,
  isLoading: false,
  isNoData: true,
  isCanceling: false,
  canceledData: null,
  businessCount: [],
});

const getters = {
  orderList: (state) => state.orders,
  ordersPaginatedData: (state) => state.ordersPaginatedData,
  order: (state) => state.order,
  otherPriceOfOrder: (state) => state.otherPriceOfOrder,
  productQuantityOfOrder: (state) => state.productQuantityOfOrder,
  buyerOfOrder: (state) => state.buyerOfOrder,
  receiverOfOrder: (state) => state.receiverOfOrder,
  isLoading: (state) => state.isLoading,
  isNoData: (state) => state.isNoData,
  isCanceling: (state) => state.isCanceling,
  canceledData: (state) => state.canceledData,
  businessCount: (state) => state.businessCount,
};

const actions = {
  async getOrders({ commit }, query = {}) {
    let page = null;
    //let limit = 50;
    let limit = 5;
    let type = "diner";
    if (query !== null) {
      if (query.page !== null && query.page !== undefined) page = query.page;
      if (query.type !== null && query.type !== undefined) type = query.type;
    }
    commit("setIsLoading", true);
    await OrdersService.getOrders(type, page, limit)
      .then((data) => {
        if (data.data) {
          const orders = data.data;
          commit("setOrders", orders);
          commit("setOrdersPaginated", data);
          commit("setIsLoading", false);
          commit("setIsNoData", false);
        } else {
          commit("setIsLoading", false);
          commit("setIsNoData", true);
        }
      })
      .catch((err) => {
        console.log("error", err);
        commit("setIsLoading", false);
      });
  },
  async fetchDetailOrder({ commit }, order_id) {
    commit("setIsLoading", true);
    await OrdersService.getOrder(order_id)
      .then((data) => {
        // console.log(data)
        //額外費用、服務費
        let other_price_items = data.order_items.filter((item) => {
          return item.type === 4 || item.type === 5;
        });
        //只剩產品的 items
        let items = data.order_items.filter((item) => {
          return item.type !== 4 && item.type !== 5;
        });
        data.order_items = items;
        //數量
        let quantity = data.order_items.reduce(
          (acc, item) => acc + item.quantity,
          0
        );
        //購買人
        let buyer = data.order_addresses.find((address) => {
          return address.type === 0;
        });
        //收件人
        let receiver = data.order_addresses.find((address) => {
          return address.type === 1;
        });
        commit("setOrderDetail", data);
        commit("setOtherPriceOfOrderDetail", other_price_items);
        commit("setProductQuantityOfOrderDetail", quantity);
        commit("setBuyerOfOrderDetail", buyer);
        commit("setReceiverOfOrderDetail", receiver);
        commit("setIsLoading", false);
      })
      .catch((err) => {
        commit("setIsLoading", false);
        return Promise.reject(err);
      });
  },
  async cancelOrder({ commit }, order_id) {
    commit("setIsLoading", true);
    await OrdersService.cancelOrder(order_id).then((data) => {
      commit("saveCancelData", data);
      commit("setIsLoading", false);
    });
  },
  async getBusinessCount({ commit }) {
    commit("setIsLoading", true);
    await OrdersService.getBusinessCount()
      .then((data) => {
        if (data.data) {
          commit("setBusinessCount", data.data);
          commit("setIsLoading", false);
        } else {
          commit("setBusinessCount", []);
          commit("setIsLoading", false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        commit("setIsLoading", false);
      });
  },
};

const mutations = {
  setOrders(state, data) {
    state.orders = data;
  },
  setOrdersPaginated: (state, data) => {
    state.ordersPaginatedData = data;
  },
  setOrderDetail: (state, order) => {
    state.order = order;
  },
  setOtherPriceOfOrderDetail: (state, data) => {
    state.otherPriceOfOrder = data;
  },
  setProductQuantityOfOrderDetail: (state, total) => {
    state.productQuantityOfOrder = total;
  },
  setBuyerOfOrderDetail: (state, data) => {
    state.buyerOfOrder = data;
  },
  setReceiverOfOrderDetail: (state, data) => {
    state.receiverOfOrder = data;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsNoData(state, isNoData) {
    state.isNoData = isNoData;
  },
  setIsCanceling(state, isCanceling) {
    state.isCanceling = isCanceling;
  },
  saveCancelData(state, data) {
    let index = state.orders.findIndex((order) => order.id === data.order_id);
    if (index !== -1) state.orders[index].status = 6;
    state.canceledData = data;
  },
  setBusinessCount(state, data) {
    state.businessCount = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
