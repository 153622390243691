/*
 * @Desc:本地資料儲存方法封裝
 * @Ref:
 *  https://codertw.com/前端開發/226032/
 * @Explain:為了不new物件，只能多寫幾遍
 * @Example:
 *
 * 1、LocalStorage的使用
 * import storage from '@/utils/storage.js'
 * storage.setItem('shiguoqing0',[1,2,3,4,5,6])
 * storage.setItem('shiguoqing1',{userId:'dfdf',token:11232323})
 * storage.setItem('shiguoqing2','dfdfdf')
 * console.log(storage.getItem('shiguoqing0'))
 * console.log(storage.getItem('shiguoqing1'))
 * console.log(storage.getItem('shiguoqing2'))
 * storage.removeItem('shiguoqing2')
 *
 *
 * 2、SessionStorage的使用
 * storage.setItem('shiguoqing0',[1,2,3,4,5,6],{type:'session'})
 *
 * */
// TODO:其他方法的實現
// TODO:超時時間的設定
/*
 * 方法實現
 * */
import localstorage from "./storage/localstorage.js";
import session from "./storage/session.js";
import cookies from "./storage/cookies.js";
import json from "./storage/json.js";
/*
 * 函式體
 * */
let storage = {
  config: {
    type: "local", // local,session,cookies,json
    expires: new Date().getTime() + 100 * 24 * 60 * 60 * 1000,
  },
  getStorage(options) {
    let config = {};
    if (options) {
      config = Object.assign({}, this.config, options);
    } else {
      config = this.config;
    }
    return this.createStorage(config.type);
  },
  createStorage(type) {
    switch (type) {
      case "local":
        return localstorage;
      case "session":
        return session;
      case "cookies":
        return cookies;
      case "json":
        return json;
    }
  },
  getItem(key, options) {
    let store = this.getStorage(options);
    return store.getItem(key);
  },
  setItem(key, value, options) {
    let store = this.getStorage(options);
    store.setItem(key, value);
  },
  removeItem(key, options) {
    let store = this.getStorage(options);
    store.removeItem(key);
  },
  getAll() {},
  clear(options) {
    let store = this.getStorage(options);
    store.clear();
  },
  key(n) {},
  length() {},
  has(key) {},
  forEach(cb) {},
  deleteAllExpires() {},
  getMaxSpace(options) {
    // 獲取最大儲存空間：只有LocalStorage和SessionStorage可以使用這個方法
    let store = this.getStorage(options);
    store.getMaxSpace();
  },
  getUsedSpace(options) {
    // 獲取使用了的空間：只有LocalStorage和SessionStorage可以使用這個方法
    let store = this.getStorage(options);
    store.getUsedSpace();
  },
};

export default storage;

// https://segmentfault.com/a/1190000002458488
// 5、遍歷localStorage儲存的key
//  .length 資料總量，例：localStorage.length
//  .key(index) 獲取key，例：var key=localStorage.key(index);
// 備註：localStorage存數的資料是不能跨瀏覽器共用的，一個瀏覽器只能讀取各自瀏覽器的資料,儲存空間5M。
// 超時設定
// function(st, key, value, expires) {
//  if (st == 'l') {
//   st = window.localStorage;
//   expires = expires || 60;
//  } else {
//   st = window.sessionStorage;
//   expires = expires || 5;
//  }
//  if (typeof value != 'undefined') {
//   try {
//    return st.setItem(key, JSON.stringify({
//     data: value,
//     expires: new Date().getTime()   expires * 1000 * 60
//    }));
//   } catch (e) {}
//  } else {
//   var result = JSON.parse(st.getItem(key) || '{}');
//   if (result && new Date().getTime() < result.expires) {
//    return result.data;
//   } else {
//    st.removeItem(key);
//    return null;
//   }
//  }
// }
