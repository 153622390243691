import AuthService from "@/services/auth.service";
import storage from "@/utils/storage";

const member = JSON.parse(storage.getItem("member", { type: "session" }));
const initialState = member
  ? { status: { loggedIn: true }, member }
  : { status: { loggedIn: false }, member: null };

const state = () => ({
  isLoggedIn: false,
  member: member,
});

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  member: (state) => state.member,
};

const actions = {
  async login({ commit }, member) {
    await AuthService.login(member).then(
      (member) => {
        commit("setIsLoggedIn", member);
      },
      (error) => {
        commit("setIsLoggedInFail");
      }
    );
  },
  async logout({ commit }) {
    await AuthService.logout();
    commit("setIsLogout");
  },
};

const mutations = {
  setIsLoggedInSuccess(state, member) {
    state.isLoggedIn = true;
    state.member = member;
  },
  setIsLoggedInFail(state) {
    state.isLoggedIn = false;
    state.member = null;
  },
  setIsLogout(state) {
    state.isLoggedIn = false;
    state.member = null;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
