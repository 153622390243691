import Postal from "@/services/postal.service";

export const state = {
  cascader: [],
};

export const getters = {
  cascader: (state) => state.cascader,
};

export const actions = {
  getCascader({ commit }) {
    Postal.getCityCascader().then((data) => {
      commit("getCityCascaderSuccess", data);
    });
  },
};

export const mutations = {
  getCityCascaderSuccess(state, cascader) {
    state.cascader = cascader;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
