import {
  getCategories,
  getCategories2,
  getProducts,
  getProducts2,
  getProduct,
  getProduct2,
} from "@/utils/http.api";
import store from "@/store";
import { useLogin } from "@/hook/auth";

class ProductsService {
  getCategories(kind) {
    const { isLogin } = useLogin();
    let getList = getCategories2;
    if (isLogin) getList = getCategories;
    return getList({ kind: kind, host: window.location.host }).then((res) => {
      if (res.status === 200 || res.status === 400) {
        return res.data.data;
      }
    });
  }

  getProductsByCategoryId(category_id, kind, page = 1, limit = 500) {
    const { isLogin } = useLogin();
    let getList = getProducts2;
    if (isLogin) getList = getProducts;
    return getList({
      category_id: category_id,
      kind: kind,
      page: page,
      limit: limit,
      host: window.location.host,
    }).then((res) => {
      if (res.status === 200 || res.status === 400) {
        const containsKey = (obj, key) => Object.keys(obj).includes(key);
        const isError = containsKey(res.data, "error");
        return isError, res.data;
      }
    });
  }

  getProduct(product_id) {
    const { isLogin } = useLogin();
    let getList = getProduct2;
    if (isLogin) getList = getProduct;
    return getList(product_id, { host: window.location.host }).then((res) => {
      if (res.status === 200) {
        return res.data.data;
      } else {
        return Promise.reject(res.data);
      }
    });
  }
}

export default new ProductsService();
