const Profile = () => import("@/views/Profile");

export default [
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      needLogin: true,
    },
  },
];
