/*
 * session.js
 * sessionstorage的實現
 */
export default {
  getItem(key) {
    let item = sessionStorage.getItem(key);
    let result = /^[{[].*[}\]]$/g.test(item); // 這點要判斷是字串還是物件
    if (result) {
      return JSON.parse(item);
    } else {
      return item;
    }
  },
  setItem(key, value) {
    // 這點要判斷是字串還是物件
    if (typeof value == "string") {
      sessionStorage.setItem(key, value);
    } else {
      let item = JSON.stringify(value);
      sessionStorage.setItem(key, item);
    }
  },
  removeItem(key) {
    sessionStorage.removeItem(key);
  },
  getAll() {},
  clear() {
    sessionStorage.clear();
  },
  key(n) {},
  forEach(cb) {},
  has(key) {},
  deleteAllExpires() {},
  getMaxSpace() {
    // 獲取 sessionStorage 最大儲存容量
    if (!window.sessionStorage) {
      console.log(this.$t("STORAGE.SESSION.BROWSER_NOT_SUPPORT"));
    }
    var test = "0123456789";
    var add = function (num) {
      if (num.length == 10240) {
        test = num;
        return;
      }
      add(num);
    };
    add(test);
    var sum = test;
    var show = setInterval(function () {
      sum = test;
      try {
        window.sessionStorage.removeItem("test");
        window.sessionStorage.setItem("test", sum);
        console.log(sum.length / 1024 + "KB");
      } catch (e) {
        this.$t("STORAGE.SESSION.OVER_SIZE", { sum: sum.length / 1024 });
        clearInterval(show);
      }
    }, 0.1);
  },
  getUsedSpace() {
    // 獲取使用了 sessionStorage 的空間
    if (!window.sessionStorage) {
      console.log(this.$t("STORAGE.SESSION.BROWSER_NOT_SUPPORT"));
    }
    var size = 0;
    for (let item in window.sessionStorage) {
      if (Object.prototype.hasOwnProperty.call(window.sessionStorage, item)) {
        size = window.sessionStorage.getItem(item).length;
      }
    }
    console.log(
      this.$t("STORAGE.SESSION.USED_SIZE", { sum: (size / 1024).toFixed(2) })
    );
  },
};
