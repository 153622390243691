import { createRouter, createWebHistory } from "vue-router";
import storage from "@/utils/storage";
import auth from "@/router/modules/auth";
import index from "@/router/modules/index";
import profile from "@/router/modules/profile";
import home from "@/router/modules/home";
import product from "@/router/modules/product";
import orders from "@/router/modules/orders";
import carts from "@/router/modules/carts";
import shop from "@/router/modules/shop";
import payments from "@/router/modules/payments";
import common from "@/router/common";
import coupon from "@/router/modules/coupon";

const routes = [
  ...auth,
  ...index,
  ...profile,
  ...home,
  ...product,
  ...orders,
  ...carts,
  ...shop,
  ...payments,
  ...common,
  ...coupon,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL, // 加上這行
  // scrollBehavior() {
  //   return { top: 0 };
  // },
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from.name === 'Product' || from.name === 'ShopProduct') {
      const productTop = Number(storage.getItem("productTop") || 0);
      return {
        top: productTop - 140,
      }
    }
    return { top: 0 };
  }
});

export default router;
