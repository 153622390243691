import { createStore } from "vuex";
import auth from "./auth.module";
import basic from "./basic.module";
import member from "./member.module";
import postal from "./postal.module";
import categories from "./categories.module";
import products from "./products.module";
import orders from "./orders.module";
import carts from "./carts.module";
import shop from "./store.module";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    basic,
    member,
    postal,
    categories,
    products,
    orders,
    carts,
    shop,
  },
});
