const state = () => ({
  isAppending: false,
});

const getters = {
  isAppending: (state) => state.isAppending,
};

const actions = {
  changeAppending({ commit }, bool) {
    commit("setIsAppending", bool);
  },
};

const mutations = {
  setIsAppending(state, isAppending) {
    state.isAppending = isAppending;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
