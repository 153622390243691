{
  "I18N_MESSAGE": "您好 i18n !!",
  "COMMON": {
    "LOADING": "載入中.......",
    "LOADING_MSG": "資料載入中，請稍候！",
    "INFO": "店家資訊",
    "NEWS": "店家公告",
    "MEMBER_CENTER": "會員中心"
  },
  "SYSTEM": {
    "APP_NAME": "Vue2 應用系統",
    "LOGIN" : "登入" ,
    "USER_NAME" : "帳號",
    "PASSWORD" : "密碼"
  },
  "STORAGE": {
    "LOCAL": {
      "BROWSER_NOT_SUPPORT": "當前瀏覽器不支援 localStorage!",
      "OVER_SIZE": "{sum} KB超出最大限制",
      "USED_SIZE": "當前 localStorage 使用容量為 {sum} KB"
    },
    "SESSION": {
      "BROWSER_NOT_SUPPORT": "當前瀏覽器不支援 localStorage!",
      "OVER_SIZE": "{sum} KB超出最大限制",
      "USED_SIZE": "當前 sessionStorage 使用容量為 {sum} KB"
    }
  },
  "INDEX": {
    "BASIC": "基本資料",
    "ORDER": "消費紀錄",
    "HOME": "線上點餐",
    "SHOP_ORDER": "訂單記錄"
  },
  "PROFILE": {
    "NAME": "姓名",
    "BIRTHDAY": "生日",
    "GENDER": "性別",
    "EMAIL": "電子信箱",
    "MOBILE": "手機門號",
    "CITY": "城市",
    "CITYAREA": "區域",
    "ADDRESS": "收件人地址",
    "SEX": {
      "MALE": "男",
      "FEMALE": "女",
      "OTHER": "其他"
    }
  },
  "PRODUCTS": {
    "NO_DATA": "尚無餐點資訊",
    "NO_DATA_FOR_STORE": "尚無商品資訊",
    "SOLD_OUT": "售完",
    "ORDER_TOTAL": "訂單小計"
  },
  "PRODUCT": {
    "BUTTON": {
      "CANCEL": "取消",
      "ADD_TO_CART": "確認",
      "BACK": "回上一頁",
      "SOLD_OUT": "已售完"
    }
  },
  "ORDERS": {
    "NO_DATA": "尚無訂單資訊",
    "CANCEL": {
      "BUTTON": "我要取消",
      "SUCCESS": "訂單已取消成功！",
      "FAIL": "訂單取消失敗，請再試一次！"
    },
    "STATUS": {
      "NEW": "店家確認中",
      "PROCESSING": "店家已接單",
      "CANCEL": "訂單已取消",
      "FINISH": "訂單完成",
      "REFUND": "訂單已退款",
      "ABNORMAL": "訂單異常,請重新下單"
    },
    "FOR_HERE": "內用",
    "TO_GO": "自取",
    "ORDER_IN": "外送",
    "GOOD_COUNT": "{count}件商品",
    "MEAL_AT": "用餐時間",
    "TOGO_AT": "取餐時間"
  },
  "ORDER": {
    "STATUS": {
      "TITLE": "訂單狀態",
      "NEW": "店家確認中",
      "PROCESSING": "店家已接單",
      "CANCEL": "訂單已取消",
      "FINISH": "訂單完成",
      "REFUND": "訂單已退款",
      "ABNORMAL": "訂單異常,請重新下單"
    },
    "MEAL_WAY": {
      "TITLE": {
        "FOR_HERE": "用餐方式",
        "TO_GO": "取餐方式",
        "ORDER_IN": "取餐方式"
      },
      "FOR_HERE": "內用",
      "TO_GO": "自取",
      "ORDER_IN": "外送"
    },
    "MEAL_AT": {
      "FOR_HERE": "用餐時間",
      "TO_GO": "取餐時間"
    },
    "PEOPLE": "人數：{adult}位大人，{child}位孩童",
    "RECEIVER": {"NAME": "收件人姓名","PHONE": "收件人電話","ADDRESS": "收件人地址"},
    "PAYMENT_WAY": {"TITLE": "付款方式","CASH": "現場付款"},
    "COMMENT": "備註",
    "INVOICE": {
      "NUMBER": "發票號碼",
      "VAT_NUMBER": "統一編號",
      "CARRIER_ID": "手機載具",
      "NPO_BAN": "愛心碼",
      "INVOICE_PAPER": "索取紙本發票：是"
    },
    "TOTAL": {"GOODS": "總計","GOODS_UNIT": "項","MONEY": "金額","MONEY_UNIT": "元"},
    "BUTTON": {
      "CANCEL": "我要取消",
      "BACK": "回上一頁"
    },
    "CANCEL": {
      "BUTTON": "我要取消",
      "SUCCESS": "訂單已取消成功！",
      "FAIL": "訂單取消失敗，請再試一次！"
    }
  },
  "CART": {
    "STEP1": "確認餐點",
    "STEP2": "取餐方式/時間",
    "STEP3": "金流付款",
    "STEP4": "完成",
    "TOTAL": {"GOODS": "總計","GOODS_UNIT": "項","MONEY": "金額","MONEY_UNIT": "元"},
    "BUTTON": {
      "NEXT": "下一步",
      "BACK": "繼續選購"
    }
  },
  "CHECKOUT": {
    "BUTTON": {
      "NEXT": "確認下單",
      "BACK": "回上一頁"
    }
  },
  "ALERT": {
    "SUCCESS": "成功",
    "ERROR": "失敗",
    "UPDATED": "更新資料{msg}",
    "ADD_TO_CART": "加入購物車{msg}",
    "ADD_TO_CART_ERROR": "請確認必選項目是否都選填！",
    "CART_UPDATED": "更新數量{msg}"
  },
  "FORM": {
    "ENTRY": "請輸入{field}",
    "PICK": "請選擇{field}",
    "SAVE":"儲存變更", "SAVING": "儲存變更中...",
    "CANCEL_CONFIRM": {
      "TITLE": "取消訂單確認",
      "DESCRIPTION": "按下「確認」後，將取消此筆訂單。",
      "DESCRIPTION_PS": "注意：訂單取消後，您需重新下單",
      "CONFIRM": "確認",
      "CANCEL": "取消"
    }
  }
}