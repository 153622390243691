import storage from "@/utils/storage";
import StoreService from "@/services/store.service";

storage.config = { type: "session" };
const state = () => ({
  brand_name: null,
  brand_logo: null,
  brand_line_name: null,
  brand_line_id: null,
  brand_city: null,
  brand_cityarea: null,
  brand_address: null,
  brand_phone: null,
  brand_other: null,
  brand_banners: null,
  //Add By Bryant
  brand_dm_mode_setting: null,
  open_hours: null,
  shop_news: null,
  setting: null,
  closed: null,
  store_type: null,
  is_brand_live: false,
  shop_shipping: null,
  shop_pay: null,
  is_invoice: null,
  tables: null,
  tableItem: null,
  fee: null,
  expiry_date: null,
  title_name: null,
  tax_id: null
});

const getters = {
  brand_name: (state) => state.brand_name,
  brand_logo: (state) => state.brand_logo,
  brand_line_name: (state) => state.brand_line_name,
  brand_line_id: (state) => state.brand_line_id,
  brand_city: (state) => state.brand_city,
  brand_cityarea: (state) => state.brand_cityarea,
  brand_address: (state) => state.brand_address,
  brand_phone: (state) => state.brand_phone,
  brand_other: (state) => state.brand_other,
  brand_banners: (state) => state.brand_banners,
  brand_dm_mode_setting: (state) => state.brand_dm_mode_setting,
  open_hours: (state) => state.open_hours,
  shop_news: (state) => state.shop_news,
  setting: (state) => state.setting,
  closed: (state) => state.closed,
  store_type: (state) => state.store_type,
  shop_shipping: (state) => state.shop_shipping,
  shop_pay: (state) => state.shop_pay,
  is_brand_live: (state) => state.is_brand_live,
  is_invoice: (state) => state.is_invoice,
  tables: (state) => state.tables,
  tableItem: (state) => state.tableItem,
  fee: (state) => state.fee,
  expiry_date: (state) => state.expiry_date,
  title_name: (state) => state.title_name,
  tax_id: (state) => state.tax_id
};

const actions = {
  getLoad({ commit }) {
    const brand = storage.getItem("brand");
    if (brand !== undefined && brand !== null) {
      commit("setBrandName", brand.title);
      commit("setBrandLogo", brand.logo);
      commit("setBrandLineName", brand.line_name);
      commit("setBrandLineId", brand.line_id);
      commit("setBrandCity", brand.city);
      commit("setBrandCityarea", brand.cityarea);
      commit("setBrandAddress", brand.address);
      commit("setBrandPhone", brand.phone);
      commit("setBrandOther", brand.other);
      commit("setBrandBanners", brand.brand_banners);
      //Add By Bryant
      commit("setDmModeSetting", brand.dm_mode_setting);
      commit("setFee", brand.fee);
      commit("setExpiryDate", brand.expiry_date);
      commit("setTitleName", brand.title_name);
      commit("setTaxId", brand.tax_id);
    }

    const store_hours = storage.getItem("store_hours");
    if (store_hours !== undefined && store_hours !== null)
      commit("setOpenHours", store_hours);

    const store_news = storage.getItem("store_news");
    if (store_news !== undefined && store_news !== null)
      commit("setStoreNews", store_news);

    const store = storage.getItem("store");
    // console.log(store)
    if (store !== undefined && store !== null)
      commit("setSetting", {
        // 店家外送
        delivery: Object.prototype.hasOwnProperty.call(store, "diner_online_delivery")
          ? { ...store.diner_online_delivery }
          : {},
        // 預約內用
        here: Object.prototype.hasOwnProperty.call(store, "diner_online_here")
          ? { ...store.diner_online_here }
          : {},
        // 現場點餐
        order: Object.prototype.hasOwnProperty.call(store, "diner_online_order")
          ? { ...store.diner_online_order }
          : {},
        // 到店自取
        togo: Object.prototype.hasOwnProperty.call(store, "diner_online_togo")
          ? { ...store.diner_online_togo }
          : {},
        // limit: Object.prototype.hasOwnProperty.call(store, "limit")
        //   ? store.limit
        //   : 0,
        // preparation_min: Object.prototype.hasOwnProperty.call(
        //   store,
        //   "preparation_min"
        // )
        //   ? store.preparation_min
        //   : 0,
      });

    const store_type = storage.getItem("store_type");
    if (store_type !== undefined && store_type !== null)
      commit("setStoreType", store_type);

    const store_closed = storage.getItem("store_closed");
    if (store_closed !== undefined && store_closed !== null)
      commit("setClosed", store_closed);

    const shop_shipping = storage.getItem("shop_shipping");
    const shop_pay = storage.getItem("shop_pay");
    const shop_is_invoice = storage.getItem("shop_is_invoice");
    if (shop_is_invoice !== undefined && shop_is_invoice !== null)
      commit("setIsInvoice", shop_is_invoice);

    const obj_pay_default = {
      pay_method_id: 0,
      name: null,
      fee: 0,
      free: 0,
      setting: [],
      description: null,
      description_diner: null,
      status: 0,
      status_diner: 0
    };
    let obj_pay = {
      9: { ...obj_pay_default },
      17: { ...obj_pay_default },
      18: { ...obj_pay_default },
      19: { ...obj_pay_default },
      10: { ...obj_pay_default },
    };
    if (shop_pay && shop_pay !== undefined) {
      // console.log(shop_pay)
      shop_pay.forEach((data) => {
        let key = data.pay_method_id;
        if (Object.prototype.hasOwnProperty.call(obj_pay, key)) {
          obj_pay[key] = {
            pay_method_id: key,
            name: data.name,
            fee: data.fee,
            free: data.free,
            setting: data.setting,
            description: data.description,
            description_diner: data.description_diner,
            status: data.status,
            status_diner: data.status_diner,
          };
        }
      });
      commit("setShopPay", {
        bank: obj_pay[9],
        cod: obj_pay[17],
        store: obj_pay[18],
        credit: obj_pay[19],
        linepay: obj_pay[10]
      });
    }

    const obj_default = {
      id: 0,
      name: null,
      description: null,
      fee: 0,
      free: 0,
      shipping_id: 0,
      status: 0,
    };
    let delivery_status = 0;
    let obj = { 1: obj_default, 2: obj_default, 3: obj_default };
    // console.log(shop_shipping)
    if (shop_shipping !== undefined && shop_shipping !== null) {
      shop_shipping.forEach((data) => {
        let key = data.shipping_id;
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = {
            id: data.id,
            name: data.shipping_name,
            description: data.shipping_description,
            fee: data.shipping_fee,
            free: data.shipping_free,
            shipping_id: data.shipping_id,
            status: data.status,
            pre_order_after_days: data.pre_order_after_days,
            pre_order_within_days: data.pre_order_within_days
          };
          if ([2, 3].includes(key) && data.status === 1) delivery_status = 1;
        }
      });
    }
    commit("setShopShipping", {
      instore: obj[1],
      delivery: { amb: obj[2], cold: obj[3], status: delivery_status }
    });

    //pay
  },
  async getTablesCascader({ commit }) {
    await StoreService.getTablesCascader()
      .then((data) => {
        commit("getTablesSuccess", data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  async getTablesNum({ commit, state }, areaValue) {
    // console.log(state.tables)
    const tablesObj = state.tables.find(item => item.value === areaValue)
    commit('setTablesNum', tablesObj)
  },
  async getBrand({ commit }) {
    await StoreService.getBrand()
      .then((data) => {
        //get success
        commit("setIsBrandLive", true);
      })
      .catch((err) => {
        console.log("我是brand 的 error");
        commit("setIsBrandLive", false);
      });
  },
};

const mutations = {
  setBrandName(state, data) {
    state.brand_name = data;
  },
  setBrandLogo(state, data) {
    state.brand_logo = data;
  },
  setBrandLineName(state, data) {
    state.brand_line_name = data;
  },
  setBrandLineId(state, data) {
    state.brand_line_id = data;
  },
  setBrandCity(state, data) {
    state.brand_city = data;
  },
  setBrandCityarea(state, data) {
    state.brand_cityarea = data;
  },
  setBrandAddress(state, data) {
    state.brand_address = data;
  },
  setBrandPhone(state, data) {
    state.brand_phone = data;
  },
  setBrandOther(state, data) {
    state.brand_other = data;
  },
  setBrandBanners(state, data) {
    state.brand_banners = data;
  },
  //Add By Bryant
  setDmModeSetting(state, data) {
    state.brand_dm_mode_setting = data;
  },
  setOpenHours(state, data) {
    state.open_hours = data;
  },
  setStoreNews(state, data) {
    state.store_news = data;
  },
  setSetting(state, data) {
    state.setting = data;
  },
  setStoreType(state, data) {
    state.store_type = data;
  },
  setClosed(state, data) {
    state.closed = data;
  },
  setIsBrandLive(state, is_brand_live) {
    state.is_brand_live = is_brand_live;
  },
  setShopShipping(state, data) {
    state.shop_shipping = data;
  },
  setShopPay(state, data) {
    state.shop_pay = data;
  },
  setIsInvoice(state, is_invoice) {
    state.is_invoice = is_invoice;
  },
  getTablesSuccess(state, data) {
    state.tables = data;
  },
  setTablesNum(state, tablesObj) {
    state.tableItem = { ...tablesObj }
  },
  setFee(state, fee) {
    state.fee = fee;
  },
  setExpiryDate(state,expiry_date){
    state.expiry_date = expiry_date
  },
  setTitleName(state,title_name){
    state.title_name = title_name
  },
  setTaxId(state,tax_id){
    state.tax_id = tax_id
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
