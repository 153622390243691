module.exports = [
  {
    type: "get",
    url: "/profile/points",
    response: () => {
      return {
        points_total: 80,
        pagination: {
          total: 111,
          per_page: 50,
          current_page: 1,
          last_page: 2,
          from: 1,
          to: 50,
        },
        data: [
          {
            id: 1,
            order_id: 1,
            points: 108,
            expiry_date: "2020-10-30",
            status: 1,
            comment: "訂單產生",
            created_at: "2020-01-01 13:20:29",
            updated_at: "2020-01-07 14:30:21",
          },
          {
            id: 3,
            order_id: "",
            points: -10,
            expiry_date: "2020-10-30",
            status: 1,
            comment: "多給點數，手動扣回",
            created_at: "2020-01-01 13:20:29",
            updated_at: "2020-01-07 14:30:21",
          },
          {
            id: 5,
            order_id: "",
            points: 10,
            expiry_date: "2020-10-30",
            status: 1,
            comment: "pos 失效，多給予10點",
            created_at: "2020-01-01 13:20:29",
            updated_at: "2020-01-07 14:30:21",
          },
          {
            id: 6,
            order_id: "",
            points: -10,
            expiry_date: "2020-10-30",
            status: 1,
            comment: "多給點數，手動扣回",
            created_at: "2020-01-01 13:20:29",
            updated_at: "2020-01-07 14:30:21",
          },
          {
            id: 7,
            order_id: "",
            points: 10,
            expiry_date: "2020-10-30",
            status: 1,
            comment: "pos 失效，多給予10點",
            created_at: "2020-01-01 13:20:29",
            updated_at: "2020-01-07 14:30:21",
          },
          {
            id: 8,
            order_id: "",
            points: 10,
            expiry_date: "2020-10-30",
            status: 1,
            comment: "pos 失效，多給予10點",
            created_at: "2020-01-01 13:20:29",
            updated_at: "2020-01-07 14:30:21",
          },
          {
            id: 9,
            order_id: "",
            points: 10,
            expiry_date: "2020-10-30",
            status: 1,
            comment: "pos 失效，多給予10點",
            created_at: "2020-01-01 13:20:29",
            updated_at: "2020-01-07 14:30:21",
          },
        ],
      };
    },
  },
];
