const Login = () => import("@/views/LineLogin");
const LineAddFriend = () => import("@/views/LineAddFriend");

export default [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      needLogin: false,
    },
  },
  {
    path: "/add-friend",
    name: "LineAddFriend",
    component: LineAddFriend,
    meta: {
      needLogin: false,
    },
  },
];
