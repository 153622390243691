import storage from "@/utils/storage";
import { getLineID, getBrands, getTables } from "@/utils/http.api";

class StoreService {
  constructor() {
    this.storage = storage;
    this.storage.config = { type: "session" };
  }

  async getBrand() {
    console.log('進入getBrand')
    await getBrands({ host: window.location.host }).then((res) => {
      // console.log(res.data)
      if (res.status == 200) {
        const store_type = [
          "okshop+df",
          "okshop+d",
          "okshop+s",
          "okshop+dfs",
          "okshop+ds",
          "okshop+od",
          "okshop+os",
          "okshop+ods",
        ];
        this.storage.setItem("brand", {
          logo: res.data.data.logo,
          title: res.data.data.name,
          line_name: res.data.data.line_name,
          line_id: res.data.data.line_id,
          phone: res.data.data.phone,
          city: res.data.data.city,
          cityarea: res.data.data.cityarea,
          address: res.data.data.address,
          other: res.data.data.other,
          brand_banners: res.data.data.brand_banners,
          //Add By Bryant
          dm_mode_setting: res.data.data.dm_mode_setting,
          brand_type: res.data.setting.brand_type,
          fee: res.data.setting.fee,
          expiry_date: res.data.setting.expiry_date,
          title_name: res.data.data.title_name || '營業人名稱',
          tax_id: res.data.data.tax_id || '統一編號'
        });
        this.storage.setItem("store_hours", res.data.setting.business_hours);
        this.storage.setItem("store_news", res.data.setting.comment);
        this.storage.setItem("store", res.data.setting.other);
        this.storage.setItem(
          "store_type",
          store_type[res.data.setting.brand_type]
        );
        this.storage.setItem("store_closed", res.data.setting.before_closed);
        if (res.data.shop_shipping !== undefined)
          this.storage.setItem("shop_shipping", res.data.shop_shipping);
        if (res.data.shop_pay !== undefined)
          this.storage.setItem("shop_pay", res.data.shop_pay);

        this.storage.setItem("shop_is_invoice", res.data.is_invoice);
        this.storage.setItem(
          "shop_product_image_show",
          res.data.product_image_show
        );
        return true;
      } else {
        return Promise.reject("store is not found");
      }
      
    });
  }

  getTables() {
    return getTables().then((res) => {
      if (res.status === 200) {
        return res.data.data;
      } else {
        return Promise.reject(res.data);
      }
    });
  }
  async getTablesCascader() {
    let tablesCascaderData = await this.getTables()
    // console.log(tablesCascaderData)
    let options = []
    if (tablesCascaderData) {
      tablesCascaderData.forEach((item1) => {
        let parentOption = {}
        parentOption.label = item1.name
        parentOption.value = item1.id
        parentOption.children = []
        if (item1.child.length > 0) {
          item1.child.forEach((item2) => {
            let childOption = {}
            childOption.label = item2.name
            childOption.value = item2.id
            parentOption.children.push(childOption)
          })
        }
        options.push(parentOption)
      })
    }
    return options
  }

  async isStoreLife() {
    if (this.storage.getItem("liff-id") !== null) return Promise.resolve(true);

    return await getLineID({ host: window.location.host }).then((res) => {
      if (res.status == 200) {
        this.storage.setItem("liff-id", res.data["liff-id"]);
        return true;
      }
      return Promise.reject("store line@ not found");
    });
  }
}

export default new StoreService();
