const CouponRecord = () => import("@/views/coupon-record/index.vue");
const CouponDetail = () => import("@/views/coupon-detail/index.vue");

export default [
  {
    path: "/coupon-record",
    name: "CouponRecord",
    component: CouponRecord,
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/coupon-detail/:id",
    name: "CouponDetail",
    component: CouponDetail,
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/cooperation-coupon-detail/:id",
    name: "CooperationCouponDetail",
    component: CouponDetail,
    meta: {
      needLogin: true,
    },
  },
];
