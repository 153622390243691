import MemberService from "@/services/member.service";

export const state = {
  isLoading: false,
  profile: {},
  defaultAddress: {},
  isUpdating: false,
  updatedData: null,
  homepage: {},
};

export const getters = {
  isLoading: (state) => state.isLoading,
  profile: (state) => state.profile,
  isUpdating: (state) => state.isUpdating,
  updatedData: (state) => state.updatedData,
  defaultAddress: (state) => {
    let address = undefined;
    if (Object.prototype.hasOwnProperty.call(state.profile, "addresses")) {
      address = state.profile.addresses.find(
        (address) => address.is_defaut === 1
      );
    }
    if (address === undefined) {
      address = {
        city_id: 0,
        cityarea_id: 0,
        address: "",
      };
    }
    return address;
  },
};

export const actions = {
  async getProfile({ commit }) {
    commit("setIsLoading", true);
    await MemberService.getProfile()
      .then((data) => {
        commit("getProfileSuccess", data);
        commit("setIsLoading", false);
      })
      .catch((err) => {
        commit("setIsLoading", false);
        return Promise.reject(err);
      });
  },
  async updateProfile({ commit }, profile) {
    commit("setProfileIsUpdating", true);
    await MemberService.updateProfile(profile)
      .then((data) => {
        commit("saveUpdatedProfile", data);
        commit("setProfileIsUpdating", false);
      })
      .catch((err) => {
        console.log("error", err);
        commit("setProfileIsUpdating", false);
      });
  },
  getDefaultAddress({ commit, getters }) {
    commit("setDefaultAddress", getters.defaultAddress);
  },
  clearUpdatedProfile({ commit }) {
    commit("saveUpdatedProfile", null);
  },
  updateProfileInput({ commit }, e) {
    commit("setProfileInput", e);
  },
  updateDefaultAddressInput({ commit }, e) {
    commit("setDeaultAddressChange", e);
  },
  async getHompageProfileAction({ commit }) {
    const result = await MemberService.getHompageProfile();
    commit("setHomepageProfile", result);
  },
};

export const mutations = {
  getProfileSuccess(state, profile) {
    state.profile = profile;
  },
  saveUpdatedProfile(state, data) {
    state.updatedData = data;
  },
  setProfileInput(state, e) {
    let data = state.profile;
    data[e.target.name] = e.target.value;
    state.profile = data;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setProfileIsUpdating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  setDefaultAddress(state, data) {
    state.defaultAddress = data;
  },
  setDeaultAddressChange(state, e) {
    let data = state.defaultAddress;
    data[e.target.name] = e.target.value;
    state.defaultAddress = data;
  },
  setHomepageProfile(state, info) {
    state.homepage = info;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
