/*
 * json.js
 * json的實現，這輩子估計也沒有時間實現了
 */
export default {
  getItem(key) {},
  setItem(key, value) {},
  removeItem(key) {},
  getAll() {},
  clear() {},
  key(n) {},
  forEach(cb) {},
  has(key) {},
  deleteAllExpires() {},
};
